import {isMobile} from 'react-device-detect';
import './App.css';

import millennialsMushroom from './images/millennials-mushroom.jpg';
import facebookLogo from './images/facebook.png';
import gmailLogo from './images/gmail.png';
import googleMapsLogo from './images/google-maps.png';
import ringing from './images/ringing.png';

function App() {
  
  const facebookLink= isMobile ? "fb://profile/100086347765649" : "https://www.facebook.com/people/Millennials-Mushroom/100086347765649/";
  
  return (
    <div className="App">

      <header className="App-header">

        <img src={millennialsMushroom} className="App-logo" alt="logo" />
        <br />
        <h2>Welcome to</h2>
        <h1>Millennials Mushroom</h1>

      </header>

      <div className="App-body">

        <p>

          <h2>We are working on our super awesome website</h2>
          <h3>In the meantime reach us at</h3>
          
        </p>

        <p className="Logo-menu">

          <a 
            href={facebookLink} 
            title="Facebook" 
            target="_blank" 
            rel="noopener noreferrer">
            <img src={facebookLogo} alt="facebook-logo" style={{ marginRight: "25px" }} />
          </a>


          <a href="mailto:millennialsmushroom@gmail.com" title="Email" target="_blank" rel="noopener noreferrer">
            <img src={gmailLogo} alt="gmail-logo" style={{ marginRight: "10px" }} />
          </a>


          <a href="http://maps.apple.com/?q=Millennials+Mushroom" title="Location" target="_blank" rel="noopener noreferrer">
            <img src={googleMapsLogo} alt="google-maps-logo" style={{ marginRight: "5px" }} />
          </a>

        </p>


        <p>

          OR
          <br />
          <h3>Call us at</h3>
          <span style={{"font-size": "1.5em"}}>

            <a href="tel:+8801409819277" 
              title="Phone" 
              className={isMobile ? '' : 'disable-link'} 
              style={{textDecoration: "underline", color: "black", outline:"none", 
                display: "flex", justifyContent:"center", alignItems: "center"}}
            >
              <img src={ringing} alt="Call +880-140-981-9277" style={{marginRight: "10px"}}/> 
              +8801409819277
            </a>

          </span>

        </p>     

      </div>
      
    </div>
  );
}

export default App;
